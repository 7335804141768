<template>
  <v-row>
    <v-col cols="3">
      <v-autocomplete
        outlined
        flat
        dense
        background-color="white"
        item-text="name"
        return-object
        v-model="bank"
        :items="listBank"
        @change="onChangebank"
        label="Bank"
      ></v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-autocomplete
        outlined
        flat
        dense
        background-color="white"
        item-text="number"
        item-value="id"
        v-model="params.bankAccountNumberId"
        :items="accountNumbers"
        label="Account Number"
      ></v-autocomplete>
    </v-col>
    <v-col cols="3">
      <v-menu
        v-model="menuStartDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            flat
            dense
            background-color="white"
            v-model="params.startDate"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            label="Start Date"
          ></v-text-field>
        </template>
        <v-date-picker v-model="params.startDate" @input="menuStartDate = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-menu
        v-model="menuEndDate"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            outlined
            flat
            dense
            background-color="white"
            v-model="params.endDate"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            label="End Date"
          ></v-text-field>
        </template>
        <v-date-picker v-model="params.endDate" @input="menuEndDate = false"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-btn color="success" @click="excel">
        <v-icon>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";
import { mapState } from "vuex";

export default {
  name: "report-bank-statement",
  data() {
    return {
      params: {
        bankId: null,
        bankAccountNumberId: null,
        startDate: moment()
          .startOf("month")
          .format("yyyy-MM-DD"),
        endDate: moment()
          .endOf("month")
          .format("yyyy-MM-DD"),
      },
      bank: null,
      accountNumbers: [],
      menuStartDate: false,
      menuEndDate: false,
    };
  },
  computed: {
    ...mapState("bank", ["listBank"]),
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/bankStatementExcel", this.params)
        .then(response => {
          fileDownload(response.data, `report-bank-statement-${this.bank.name}.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onChangebank(val) {
      this.accountNumbers = val.accountNumbers;
      this.params.bankId = val.id;
    },
  },
  async mounted() {
    await this.$store.dispatch("bank/dropdown");
  },
};
</script>

<style></style>
